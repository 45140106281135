<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="employee_leave_request"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'employee_id'">
          {{ getEmployeeId(props.row.employee_id) }}
        </span>
        <span v-else-if="props.column.field === 'name'">
          {{ employeeNameById(props.row.employee_id) }}
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-button variant="primary" @click="viewDetails(props.row)">
              <feather-icon class="text-body " icon="EyeIcon" size="15" />
          </b-button>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="props.row.status===0 && (findAccess(props.row.id) || user.role_id===1)" variant="primary" @click="remove(props.row.id)">
                <feather-icon class="text-body " icon="CheckIcon" size="15" />
              </b-button>
              <b-button v-if="props.row.status===0 && (findAccess(props.row.id) || user.role_id===1)" variant="danger" @click="decliend(props.row.id)">
                <feather-icon class="text-body " icon="CrosshairIcon" size="15" />
              </b-button>
              <b-button v-if="props.row.status===0 && props.row.approvalable.length<1 && user.role_id===1" variant="secondary" @click="showModal(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="props.row.status===0 && findAccess(props.row.id)" variant="secondary" @click="showModal(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
             hide-footer size="sm"
             title="Leave request forward"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="leave_request">
          <b-row>
            <b-col md="8">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="employee"
                    rules="required"
                >
                  <select name="employee_id" v-model="employee_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(employee, key) in employees" :value="employee.id" :key="key">{{employeeNameById(employee.id)}}- {{employee.employee_id}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="comment"
                    rules="required"
                >
                  <b-form-textarea
                    v-model="comment"
                    name="comment"
                    :state="errors.length > 0 ? false:null"
                    placeholder="comment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                {{ $t('Submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
<!-- status modal   -->
    <b-modal ref="my-modal2"
             hide-footer size="lg"
             :title="'Leave Status'"
    >
      <b-row>
        <p class="ml-5">{{$t('Status: '+ status[parseInt(selected_row.status)] )}}</p>
        <b-col md="12">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Employee ID</b-th>
              <b-th class="text-center"  :colspan="1">Employee Name</b-th>
              <b-th class="text-center"  :colspan="1">Comment</b-th>
              <b-th class="text-center"  :colspan="1">Status</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(grade,k) in selected_row.approvalable" :key="k">
                <b-th class="text-center">{{ getEmployeeId(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ employeeNameById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ grade.comment }}</b-th>
                <b-th class="text-center">{{ status2[parseInt(grade.status)] }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,BModal,BButton,BFormTextarea,
    BForm,BRow,BCol,BTableSimple,BThead,BTr,BTh,BTbody,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import apiCall from "@/libs/axios";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'LeaveRequest',
  components: {
    BCardCode,BFormTextarea,
    VueGoodTable,BForm,
    BPagination,
    BFormGroup,
    BFormInput,BRow,BCol,BTableSimple,BThead,BTr,BTh,BTbody,
    BFormSelect,ValidationProvider, ValidationObserver,
    BModal,BButton, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Employee ID',
          field: 'employee_id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Date',
          field: 'leave_date',
        },
        {
          label: 'description',
          field: 'description',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status:{
        0:'Pending',
        1:'Approved',
        2:'Declined'
      },
      status2:{
        0:'Pending',
        1:'Approved',
        2:'Foreword',
        3:'Declined'
      },
      employee_id:null,
      comment:'',
      selected_row:{},
      columns10: [
        {
          label: 'Employee ID',
          field: 'EmployeeId',
        },
        {
          label: 'Employee Name',
          field: 'EmployeeName',
        },
        {
          label: 'Date',
          field: 'Date',
        },
        {
          label: 'Description',
          field: 'Description',
        },
        {
          label: 'Status',
          field: 'Status',
        }
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['employee_leave_request','employees','employeeIdById','getEmployeeId', 'employeeNameById']),
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    },
    getData() {
      return this.employee_leave_request.map(item => ({
        EmployeeId: item.employee_id ? this.employeeIdById(item.employee_id) : '',
        EmployeeName: item.employee_id ? this.employeeNameById(item.employee_id) : '',
        Date: item.leave_date ? item.leave_date : '',
        Description: item.description ? item.description : '',
        Status: item.status == 1 ? 'Approved' : (item.status == 2 ? 'Declined' : (!item.status ? 'Pending' : '')),
      }));
    },
  },
  created() {
    this.$store.dispatch('GET_ALL_EMPLOYEE_LEAVE_REQUEST');
    if(this.employees.length < 1) this.$store.dispatch('GET_ALL_EMPLOYEE');
  },
  methods: {
    showModal(row) {
      this.selected_row=row;
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    viewDetails(row){
      this.selected_row=row;
      this.$refs['my-modal2'].show()
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.leave_request);
          apiCall.post(`/employee/leave/request/forward${this.selected_row.id}`,data).then((response)=>{
            this.hideModal();
            this.$toaster.success(response.data.message);
            this.selected_row={};
            this.employee_id=null;
            this.comment='';
            this.$store.dispatch('GET_ALL_EMPLOYEE_LEAVE_REQUEST');
          }).catch((error)=>{
            if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    remove(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to approve this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/employee/leave/request/approve${id}`).then((response)=>{
            this.$store.dispatch('GET_ALL_EMPLOYEE_LEAVE_REQUEST');
            this.$swal({
              icon: 'success',
              title: 'Status Change!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    decliend(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to decliend this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, decliend it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/employee/leave/request/declined${id}`).then((response)=>{
            this.$store.dispatch('GET_ALL_EMPLOYEE_LEAVE_REQUEST');
            this.$swal({
              icon: 'success',
              title: 'Status Change!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    findAccess(id){
      let ff = this.employee_leave_request.find(item=>item.id===parseInt(id));
      if(ff){
        if(ff.approvalable.length<1) return false;
        else return ff.approvalable[ff.approvalable.length -1].employee_id===this.user.userable.id;
      }
      else return false;
    },
    pdfGenerate() {
      let title = "Employee Leave Request";
      let clm = ['EmployeeId','EmployeeName','Date','Description','Status'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'leave_request_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>